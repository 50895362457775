import {
  BatchCreateTransactionRequest,
  Transaction,
} from '../proto/trnspb/transaction_grpc_web_pb';

import {
  stringToProtoDate,
  stringToProtoTradeTimeStamp,
} from './ConvertService';

import { stringToProtoTransactionMetadata } from 'services/TransactionMetadata';
import { some } from 'lodash';

/*Defines metadata for request*/
export const meta = { 'manual-request': '' };

export class BatchPartialError extends Error {
  constructor(msg, list) {
    super(msg);
    // list is an array of TransactionResponse.toObject()
    this.list = list;
  }

  getErrors() {
    return this.list
      .map((o, i) => ({
        index: i + 1,
        ...o,
      }))
      .filter((o) => o.status === 'rejected' && o.errorMessage);
  }
}

const requestTransactionParameter = (list, activeUser) => {
  let listReq = [];

  list.forEach((row) => {
    let req = new Transaction();

    req.setEntryType(row.entryType);
    req.setEntrySubType(row.entrySubtype);
    req.setCorrespondent(row.correspondent);
    req.setAccountNo(row.accountNo);
    req.setContraCorrespondent(row.contraCorrespondent);
    req.setContraAccountNo(row.contraAccountNo);
    req.setSide(row.side);
    req.setSymbol(row.symbol);
    req.setCusip(row.cusip);
    req.setQty(row.qty);
    req.setPrice(row.price);
    req.setGrossAmt(row.grossAmt);
    req.setNetAmt(row.netAmt);
    req.setTradeAt(stringToProtoTradeTimeStamp(row.tradeDate));
    req.setSettleDate(stringToProtoDate(row.settleDate));
    req.setHoldDate(stringToProtoDate(row.holdDate));
    req.setDescription(row.description);
    req.setExecutionId(row.executionId);
    req.setExternalId(row.externalId);
    req.setExecutingVenue(row.executingVenue);
    req.setOrderId(row.orderId);
    req.setLeavesQty(row.leavesQty);
    req.setFees(row.fees);
    req.setContraAccountDesignator(row.contraAccountDesignator);
    req.setVendor(row.vendor);
    req.setCreatedBy(activeUser);
    req.setRefId(row.refId);
    if (row.contraSymbol && row.contraSymbol !== 'USD') {
      req.setContraSymbol(
        row.contraSymbol.startsWith('$')
          ? row.contraSymbol
          : '$' + row.contraSymbol
      );
    }
    req.setSwapRate(row.swapRate);
    req.setSwapRateAt(stringToProtoTradeTimeStamp(row.swapRateAt));
    req.setLocalGrossAmt(row.localGrossAmt);
    req.setLocalNetAmt(row.localNetAmt);
    req.setSwapFeeBpsAlpa(row.swapFeeBpsAlpa);
    req.setSwapFeeBpsCorr(row.swapFeeBpsCorr);
    req.setMetadata(
      row.metadata || row.localFees
        ? stringToProtoTransactionMetadata(row.metadata, row.localFees)
        : null
    );

    listReq.push(req);
  });

  return listReq;
};

const batchSize = 1024;

async function executeBatchCreateTransaction(req, client) {
  return new Promise((resolve, reject) => {
    client.batchCreateTransaction(req, meta, (error, response) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function batchCreateTransaction(
  param,
  client,
  activeUser,
  allOrNone
) {
  let responses = [];

  try {
    if (allOrNone) {
      let batchReq = new BatchCreateTransactionRequest();
      let listReq = requestTransactionParameter(param, activeUser);
      batchReq.setTransactionsList(listReq);
      batchReq.setAllOrNone(true);
      const res = await executeBatchCreateTransaction(batchReq, client);
      if (some(res.transactionResponsesList, (o) => o.status !== 'executed')) {
        console.log(res.transactionResponsesList);
        throw new BatchPartialError(
          'partial batch error',
          res.transactionResponsesList
        );
      }
    } else {
      for (let i = 0; i < param.length; i += batchSize) {
        let chunk = param.slice(i, i + batchSize);
        let listReq = requestTransactionParameter(chunk, activeUser);
        let batchReq = new BatchCreateTransactionRequest();
        batchReq.setTransactionsList(listReq);

        const res = await executeBatchCreateTransaction(batchReq, client);
        responses = [...responses, ...res.transactionResponsesList];
      }
    }
  } catch (error) {
    if (error instanceof BatchPartialError) {
      throw error;
    } else {
      throw new Error(
        `Batch creation failed due to error: ${error.message} Executed the first ${responses.length} row(s).`
      );
    }
  }
  return responses;
}

async function executeBatchCreatePendingTransaction(req, client) {
  return new Promise((resolve, reject) => {
    client.batchCreatePendingTransaction(req, meta, (error, response) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function batchCreatePendingTransaction(param, client, activeUser) {
  let responses = [];

  try {
    for (let i = 0; i < param.length; i += batchSize) {
      let chunk = param.slice(i, i + batchSize);
      let listReq = requestTransactionParameter(chunk, activeUser);
      let batchReq = new BatchCreateTransactionRequest();
      batchReq.setTransactionsList(listReq);

      const res = await executeBatchCreatePendingTransaction(batchReq, client);
      responses = [...responses, ...res.transactionResponsesList];
    }
  } catch (error) {
    throw new Error(
      `Batch pending creation failed due to error: ${error.message} Executed the first ${responses.length} row(s).`
    );
  }
  return responses;
}

export const convertDataToTransactionModel = (d) => {
  let dt = GetValueByOjbectName('Trade Date', d).split(/ /);
  dt.shift();
  let tradeAt = dt.join(' ');

  return {
    correspondent: GetValueByOjbectName('Correspondent', d),
    accountNo: GetValueByOjbectName('Account No', d),
    entryType: GetValueByOjbectName('Entry Type', d),
    entrySubtype: GetValueByOjbectName('Entry Subtype', d),
    tradeDate: GetValueByOjbectName('Trade Date', d),
    tradeAt: tradeAt,
    symbol: GetValueByOjbectName('Symbol', d),
    cusip: GetValueByOjbectName('Cusip', d),
    side: GetValueByOjbectName('Side', d),
    qty: GetValueByOjbectName('Qty', d),
    price: GetValueByOjbectName('Price', d),
    contraAccountNo: GetValueByOjbectName('Contra Account No', d),
    settleDate: GetValueByOjbectName('Settle Date', d),
    holdDate: GetValueByOjbectName('Hold Date', d),
    grossAmt: GetValueByOjbectName('Gross Amt', d),
    netAmt: GetValueByOjbectName('Net Amt', d),
    fees: GetValueByOjbectName('Fees', d),
    description: GetValueByOjbectName('Description', d),
    executionId: GetValueByOjbectName('Execution ID', d),
    externalId: GetValueByOjbectName('External ID', d),
    executingVenue: GetValueByOjbectName('Executing Venue', d),
    leavesQty: GetValueByOjbectName('Leaves Qty', d),
    orderId: GetValueByOjbectName('Order ID', d),
    vendor: GetValueByOjbectName('Vendor', d),
    contraAccountDesignator: GetValueByOjbectName(
      'Contra Account Designator',
      d
    ),
    contraCorrespondent: GetValueByOjbectName('Contra Correspondent', d),
    refId: GetValueByOjbectName('Ref ID', d),
    contraSymbol: GetValueByOjbectName('Contra Symbol', d),
    swapRate: GetValueByOjbectName('Swap Rate', d),
    swapRateAt: GetValueByOjbectName('Swap Rate At', d),
    localPrice: GetValueByOjbectName('Local Price', d),
    localGrossAmt: GetValueByOjbectName('Local Gross Amt', d),
    localNetAmt: GetValueByOjbectName('Local Net Amt', d),
    swapFeeBpsAlpa: GetValueByOjbectName('Swap Fee Bps Alpa', d),
    swapFeeBpsCorr: GetValueByOjbectName('Swap Fee Bps Corr', d),
    metadata: GetValueByOjbectName('Metadata', d),
    localFees: GetValueByOjbectName('Local Fees', d),
    error: validateEntries(d),
  };
};

const GetValueByOjbectName = (name, d) => {
  if (Object.prototype.hasOwnProperty.call(d, name)) {
    return d[name];
  }
  return '';
};

export const checkEntryType = (e) => {
  let trd = ['TRD', 'CORR', 'FXTRD', 'OPTRD'];
  let pm = [
    'ACATS',
    'JNLS',
    'MEM',
    'OCT',
    'CNVS',
    'DRS',
    'DWAC',
    'DTC',
    'DTCS',
    'NC',
    'VOF',
    'OPEXC',
    'OPEXP',
    'OPASN',
  ];
  let cm = [
    'ACATC',
    'CSD',
    'CSW',
    'INT',
    'JNLC',
    'PTC',
    'PTR',
    'FEE',
    'CNVC',
    'DTCC',
    'FXPNL',
  ];
  let cpm = [
    'REORG',
    'SPLIT',
    'SPIN',
    'DIV',
    'ALLOC',
    'DIVNRA',
    'DIVWH',
    'DIVPWH',
    'MA',
    'SEG',
    'CIL',
    'CFEE',
    'WH',
    'SWP',
  ];

  if (trd.includes(e)) {
    return 'trd';
  } else if (pm.includes(e)) {
    return 'pm';
  } else if (cm.includes(e)) {
    return 'cm';
  } else if (cpm.includes(e)) {
    return 'cpm';
  } else {
    return;
  }
};

export const validateEntries = (d) => {
  if (!GetValueByOjbectName('Entry Type', d)) {
    return true;
  }
  if (!GetValueByOjbectName('Account No', d)) {
    return true;
  }
  if (!GetValueByOjbectName('Contra Account No', d)) {
    return true;
  }

  if (checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'trd') {
    if (!GetValueByOjbectName('Side', d)) {
      return true;
    }
    if (!GetValueByOjbectName('Trade Date', d)) {
      return true;
    }
  }
  if (
    checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'trd' ||
    checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'pm' ||
    checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'cpm'
  ) {
    if (!GetValueByOjbectName('Symbol', d)) {
      return true;
    }
  }
  if (
    checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'trd' ||
    checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'pm'
  ) {
    if (!GetValueByOjbectName('Qty', d)) {
      return true;
    }
    if (!GetValueByOjbectName('Price', d)) {
      return true;
    }
  }
  if (checkEntryType(GetValueByOjbectName('Entry Type', d)) === 'cpm') {
    if (
      !GetValueByOjbectName('Net Amt', d) &&
      !GetValueByOjbectName('Local Net Amt', d)
    ) {
      return true;
    }
    if (
      GetValueByOjbectName('Net Amt', d) &&
      GetValueByOjbectName('Local Net Amt', d)
    ) {
      return true;
    }
  }
  if ((GetValueByOjbectName('Contra Symbol', d) || 'USD') !== 'USD') {
    if (!GetValueByOjbectName('Swap Rate', d)) {
      return true;
    }
  }
  return false;
};
