import { IRADetails } from 'proto/bankpb/request_pb';
import {
  FreezeAssetDetails,
  FxCustodyBankAccounts,
  Hierarchy,
  Metadata,
} from '../proto/trnspb/transaction_pb';
import { Struct } from 'google-protobuf/google/protobuf/struct_pb.js';
import { meta } from './TransactionService';

function isObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

export function stringToProtoTransactionMetadata(metadataStr, localFees) {
  if (metadataStr === undefined || metadataStr === null || metadataStr === '') {
    return;
  }

  let obj;
  try {
    metadataStr = metadataStr.replace(/(^"|"$)/g, '');
    obj = JSON.parse(metadataStr);
  } catch {
    throw new Error('Metadata must be a valid JSON string');
  }

  if (!isObject(obj)) {
    throw new Error('Metadata must be a JSON object');
  }

  const metadata = new Metadata();
  if ('annotation' in obj) {
    const annotation = obj['annotation'];
    if (!isObject(annotation)) {
      throw new Error('Value of the "annotation" field must be a JSON object');
    }
    metadata.setAnnotation(Struct.fromJavaScript(annotation));
  }

  if ('to_open' in obj) {
    const toOpen = obj['to_open'];
    if (typeof toOpen != 'boolean') {
      throw new Error('Value of the "to_open" field must be a boolean');
    }
    metadata.setToOpen(toOpen);
  }

  if ('allow_inactive_asset' in obj) {
    const allowInactiveAsset = obj['allow_inactive_asset'];
    if (typeof allowInactiveAsset != 'boolean') {
      throw new Error(
        'Value of the "allow_inactive_asset" field must be a boolean'
      );
    }
    metadata.setAllowInactiveAsset(allowInactiveAsset);
  }

  if ('hierarchy' in obj) {
    const hierarchy = obj['hierarchy'];
    if (!isObject(hierarchy)) {
      throw new Error('Value of the "hierarchy" field must be a JSON object');
    }
    const hierarchyProto = new Hierarchy();
    if (hierarchy['parent']) {
      hierarchyProto.setParent(hierarchy['parent']);
    }
    if (hierarchy['children']) {
      hierarchyProto.setChildrenList(hierarchy['children']);
    }
    metadata.setHierarchy(hierarchyProto);
  }

  if ('payable_to' in obj) {
    const payableTo = obj['payable_to'];
    if (typeof payableTo != 'string') {
      throw new Error('Value of the "payable_to" field must be a string');
    }
    metadata.setPayableTo(payableTo);
  }

  if ('receivable_from' in obj) {
    const receivableFrom = obj['receivable_from'];
    if (typeof receivableFrom != 'string') {
      throw new Error('Value of the "receivable_from" field must be a string');
    }
    metadata.setReceivableFrom(receivableFrom);
  }

  if ('fx_custody_bank_accounts' in obj) {
    const bankAccounts = obj['fx_custody_bank_accounts'];
    if (!isObject(bankAccounts)) {
      throw new Error(
        'Value of the "fx_custody_bank_accounts" field must be a JSON object'
      );
    }

    const bankAccountsProto = new FxCustodyBankAccounts();
    if (bankAccounts['account_non_usd']) {
      bankAccountsProto.setAccountNonUsd(bankAccounts['account_non_usd']);
    }
    if (bankAccounts['account_usd']) {
      bankAccountsProto.setAccountUsd(bankAccounts['account_usd']);
    }

    metadata.setFxCustodyBankAccounts(bankAccountsProto);
  }

  if ('freeze_asset_details' in obj) {
    const freezeAssetDetails = obj['freeze_asset_details'];
    if (!isObject(freezeAssetDetails)) {
      throw new Error(
        'Value of the "freeze_asset_details" field must be a JSON object'
      );
    }

    const freezeAssetDetailsProto = new FreezeAssetDetails();
    if (freezeAssetDetails['asset_type']) {
      freezeAssetDetailsProto.setAssetType(freezeAssetDetails['asset_type']);
    }
    if (freezeAssetDetails['cusip']) {
      freezeAssetDetailsProto.setCusip(freezeAssetDetails['cusip']);
    }
    if (freezeAssetDetails['symbol']) {
      freezeAssetDetailsProto.setSymbol(freezeAssetDetails['symbol']);
    }
    if (freezeAssetDetails['symbol_description']) {
      freezeAssetDetailsProto.setSymbolDescription(
        freezeAssetDetails['symbol_description']
      );
    }

    metadata.setFreezeAssetDetails(freezeAssetDetailsProto);
  }

  if ('local_fees' in obj) {
    const localFees = obj.local_fees;
    metadata.setLocalFees(localFees);
  } else if (localFees) {
    metadata.setLocalFees(localFees);
  }

  if ('ira' in obj) {
    const ira = obj['ira'];
    if (!isObject(ira)) {
      throw new Error('Value of the "ira" field must be a JSON object');
    }
    const iraProto = new IRADetails();
    if (ira['tax_year']) {
      iraProto.setTaxYear(ira['tax_year']);
    }

    if (ira['fed_withholding_pct']) {
      iraProto.setFederalWithholdingPct(ira['fed_withholding_pct']);
    }

    if (ira['state_withholding_pct']) {
      iraProto.setStateWithholdingPct(ira['state_withholding_pct']);
    }

    if (ira['fed_withholding_amount']) {
      iraProto.setFederalWithholdingAmount(ira['fed_withholding_amount']);
    }

    if (ira['state_withholding_amount']) {
      iraProto.setStateWithholdingAmount(ira['state_withholding_amount']);
    }

    metadata.setIra(iraProto);
  }
  return metadata;
}

export function protoTransactionMetadataToString(metadata) {
  if (!metadata) {
    return '';
  }

  const hierarchy = metadata.getHierarchy()?.toObject();
  const hierarchyObj = {};
  if (hierarchy && hierarchy.parent) {
    hierarchyObj['parent'] = hierarchy.parent;
  }
  if (hierarchy && hierarchy.childrenList) {
    hierarchyObj['children'] = hierarchy.childrenList;
  }

  const fxCustodyBankAccounts = metadata.getFxCustodyBankAccounts()?.toObject();
  const fxCustodyBankAccountsObj = {};
  if (fxCustodyBankAccounts && fxCustodyBankAccounts.accountNonUsd) {
    fxCustodyBankAccountsObj['account_non_usd'] =
      fxCustodyBankAccounts.accountNonUsd;
  }
  if (fxCustodyBankAccounts && fxCustodyBankAccounts.accountUsd) {
    fxCustodyBankAccountsObj['account_usd'] = fxCustodyBankAccounts.accountUsd;
  }

  const fields = {
    annotation: {
      exists: metadata.hasAnnotation(),
      value: metadata.getAnnotation()?.toJavaScript(),
    },
    to_open: { exists: metadata.hasToOpen(), value: metadata.getToOpen() },
    hierarchy: { exists: metadata.hasHierarchy(), value: hierarchyObj },
    local_fees: {
      exists: metadata.hasLocalFees(),
      value: metadata.getLocalFees(),
    },
    payable_to: {
      exists: metadata.hasPayableTo(),
      value: metadata.getPayableTo(),
    },
    fx_custody_bank_accounts: {
      exists: metadata.hasFxCustodyBankAccounts(),
      value: fxCustodyBankAccountsObj,
    },
  };

  const object = {};
  for (const name in fields) {
    const field = fields[name];
    if (field.exists) {
      object[name] = field.value;
    }
  }
  return JSON.stringify(object);
}

export function protoTransactionMetadataLocalFees(metadata) {
  if (!metadata) {
    return;
  }

  if (metadata.localFees) {
    return metadata.localFees;
  } else if (
    typeof metadata.hasLocalFees == 'function' &&
    metadata.hasLocalFees()
  ) {
    return metadata.getLocalFees();
  }
}
