import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Fade,
  Box,
  MenuItem,
  Typography,
  Backdrop,
  InputLabel,
  Select,
  TextField,
  Link,
  Collapse,
} from '@material-ui/core';
import useStyles from '../../../styles';

export const MetadataModal = (props) => {
  const { md, setFormMd } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [metadata, setMetadata] = useState(md || {});

  const [headerMap, setHeaderMap] = useState({
    hierarchy: false,
    freeze_asset_details: false,
    ira: false,
    fx_custody_bank_accounts: false,
    annotation: false,
  });

  const [annotations, setAnnotations] = useState(md?.annotation || {});

  const classes = useStyles();

  useEffect(() => {
    if (JSON.stringify(annotations) !== '{}') {
      setMetadata({
        ...metadata,
        annotation: Object.fromEntries(annotations),
      });
    }
  }, [annotations]);

  return (
    <>
      <Link
        onClick={() => setIsOpen(true)}
        color="primary"
        style={{ display: 'flex', marginTop: '8px', cursor: 'pointer' }}
      >
        Edit
      </Link>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              Edit Metadata
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap={10}
              bgcolor="#00000010"
              borderRadius="5px"
              padding="14px"
            >
              <p style={{ fontWeight: 'bold', margin: 'auto' }}>Preview</p>
              {JSON.stringify(metadata)}
            </Box>
            <Box mt={5}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel>To Open</InputLabel>
                    <Select
                      labelId="type"
                      fullWidth
                      onChange={(e) =>
                        setMetadata({ ...metadata, on_open: e.target.value })
                      }
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </Select>
                  </div>
                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel>Allow Inactive Asset</InputLabel>
                    <Select
                      labelId="type"
                      fullWidth
                      onChange={(e) =>
                        setMetadata({
                          ...metadata,
                          allow_inactive_asset: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </Select>
                  </div>
                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel>Local Fees</InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        setMetadata({ ...metadata, local_fees: e.target.value })
                      }
                    />
                  </div>
                </Box>
                <Box display="flex" flexDirection="row">
                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel>Payable To</InputLabel>
                    <TextField
                      fullWidth
                      onChange={(e) =>
                        setMetadata({ ...metadata, payable_to: e.target.value })
                      }
                    />
                  </div>

                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel>Receivable From</InputLabel>
                    <TextField
                      fullWidth
                      onChange={(e) =>
                        setMetadata({
                          ...metadata,
                          receivable_from: e.target.value,
                        })
                      }
                    />
                  </div>
                </Box>
                <SectionHeader
                  headerMap={headerMap}
                  headerKey="annotation"
                  setHeaderMap={setHeaderMap}
                />
                <Collapse in={headerMap['annotation']}>
                  <AnnotationEditor
                    annotations={annotations}
                    setAnnotations={setAnnotations}
                  />
                </Collapse>
                <SectionHeader
                  headerMap={headerMap}
                  headerKey="hierarchy"
                  setHeaderMap={setHeaderMap}
                />
                <Collapse in={headerMap['hierarchy']}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 16 }}
                  >
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Parent</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            hierarchy: {
                              ...metadata.hierarchy,
                              parent: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Group</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            hierarchy: {
                              ...metadata.hierarchy,
                              group: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Children (comma separated)</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            hierarchy: {
                              ...metadata.hierarchy,
                              children: e.target.value.split(','),
                            },
                          })
                        }
                      />
                    </div>
                  </Box>
                </Collapse>
                <SectionHeader
                  headerMap={headerMap}
                  headerKey="freeze_asset_details"
                  setHeaderMap={setHeaderMap}
                />
                <Collapse in={headerMap['freeze_asset_details']}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 16 }}
                  >
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Asset Type</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            freeze_asset_details: {
                              ...metadata.freeze_asset_details,
                              asset_type: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Symbol</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            freeze_asset_details: {
                              ...metadata.freeze_asset_details,
                              symbol: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Cusip</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            freeze_asset_details: {
                              ...metadata.freeze_asset_details,
                              cusip: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Symbol Description</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            freeze_asset_details: {
                              ...metadata.freeze_asset_details,
                              symbol_description: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </Box>
                </Collapse>

                <SectionHeader
                  headerMap={headerMap}
                  headerKey="ira"
                  setHeaderMap={setHeaderMap}
                />
                <Collapse in={headerMap['ira']}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 16 }}
                  >
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Tax Year</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            ira: {
                              ...metadata.ira,
                              tax_year: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Fed. Withholding Percent</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            ira: {
                              ...metadata.ira,
                              fed_withholding_pct: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Fed. Withholding Amount</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            ira: {
                              ...metadata.ira,
                              fed_withholding_amount: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 16 }}
                  >
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>State Withholding Percent</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            ira: {
                              ...metadata.ira,
                              state_withholding_pct: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>State Withholding Amount</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            ira: {
                              ...metadata.ira,
                              state_withholding_amount: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </Box>
                </Collapse>

                <SectionHeader
                  headerMap={headerMap}
                  headerKey="fx_custody_bank_accounts"
                  setHeaderMap={setHeaderMap}
                />
                <Collapse in={headerMap['fx_custody_bank_accounts']}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 16 }}
                  >
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Account USD</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            fx_custody_bank_accounts: {
                              ...metadata.fx_custody_bank_accounts,
                              account_usd: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <InputLabel>Account Non USD</InputLabel>
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          setMetadata({
                            ...metadata,
                            fx_custody_bank_accounts: {
                              ...metadata.fx_custody_bank_accounts,
                              account_non_usd: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </Box>
                </Collapse>
              </Box>
              <Box display="flex" flexDirection="row" mt="24px" gridGap="24px">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setIsOpen(false);
                    setFormMd(
                      { target: { value: JSON.stringify(metadata) } },
                      'metadata'
                    );
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const AnnotationEditor = (props) => {
  const { annotations, setAnnotations } = props;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      {Object.entries(annotations)
        .concat([['', '']])
        .map((a, idx) => (
          <Box
            style={{ display: 'flex', flexDirection: 'row', gap: '48px' }}
            key={idx}
          >
            <TextField
              fullWidth
              onChange={(e) => {
                let updatedArray = [];
                if (annotations.length > 0) {
                  updatedArray = [...annotations];
                }
                updatedArray[idx] = [e.target.value, a[1][1]];
                setAnnotations(updatedArray);
              }}
              value={a[1][0]}
            />
            <TextField
              fullWidth
              onChange={(e) => {
                let updatedArray = [];
                if (annotations.length > 0) {
                  updatedArray = [...annotations];
                }
                updatedArray[idx] = [a[1][0], e.target.value];
                setAnnotations(updatedArray);
              }}
              value={a[1][1]}
            />
          </Box>
        ))}
    </Box>
  );
};

const SectionHeader = (props) => {
  const { headerMap, headerKey, setHeaderMap } = props;
  const currVal = headerMap[headerKey];

  const titleCase = (s) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : ' ' + d.toUpperCase()
    );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <p style={{ fontWeight: 'bold', marginTop: '16px' }}>
        {titleCase(headerKey)}
      </p>
      <Link
        onClick={() => setHeaderMap({ ...headerMap, [headerKey]: !currVal })}
        color="primary"
      >
        {currVal ? 'Hide' : 'Show'}
      </Link>
    </Box>
  );
};
