export const countries = [
  { id: '1', code: 'AFG', codeDescription: 'Afghanistan' },
  { id: '2', code: 'ALB', codeDescription: 'Albania' },
  { id: '3', code: 'DZA', codeDescription: 'Algeria' },
  { id: '4', code: 'ASM', codeDescription: 'American Samoa' },
  { id: '5', code: 'AND', codeDescription: 'Andorra' },
  { id: '6', code: 'AGO', codeDescription: 'Angola' },
  { id: '7', code: 'AIA', codeDescription: 'Anguilla' },
  { id: '8', code: 'ATA', codeDescription: 'Antarctica' },
  { id: '9', code: 'ATG', codeDescription: 'Antigua and Barbuda' },
  { id: '10', code: 'ARG', codeDescription: 'Argentina' },
  { id: '11', code: 'ARM', codeDescription: 'Armenia' },
  { id: '12', code: 'ABW', codeDescription: 'Aruba' },
  { id: '13', code: 'AUS', codeDescription: 'Australia' },
  { id: '14', code: 'AUT', codeDescription: 'Austria' },
  { id: '15', code: 'AZE', codeDescription: 'Azerbaijan' },
  { id: '16', code: 'BHS', codeDescription: 'Bahamas' },
  { id: '17', code: 'BHR', codeDescription: 'Bahrain' },
  { id: '18', code: 'BGD', codeDescription: 'Bangladesh' },
  { id: '19', code: 'BRB', codeDescription: 'Barbados' },
  { id: '20', code: 'BYR', codeDescription: 'Belarus' },
  { id: '21', code: 'BEL', codeDescription: 'Belgium' },
  { id: '22', code: 'BLZ', codeDescription: 'Belize' },
  { id: '23', code: 'BEN', codeDescription: 'Benin' },
  { id: '24', code: 'BMU', codeDescription: 'Bermuda' },
  { id: '25', code: 'BTN', codeDescription: 'Bhutan' },
  {
    id: '26',
    code: 'BOL',
    codeDescription: 'Bolivia (Plurinational State of)',
  },
  {
    id: '27',
    code: 'BES',
    codeDescription: 'Bonaire, Sint Eustatius and Saba',
  },
  { id: '28', code: 'BIH', codeDescription: 'Bosnia and Herzegovina' },
  { id: '29', code: 'BWA', codeDescription: 'Botswana' },
  { id: '30', code: 'BVT', codeDescription: 'Bouvet Island' },
  { id: '31', code: 'BRA', codeDescription: 'Brazil' },
  { id: '32', code: 'IOT', codeDescription: 'British Indian Ocean Territory' },
  { id: '33', code: 'BRN', codeDescription: 'Brunei Darussalam' },
  { id: '34', code: 'BGR', codeDescription: 'Bulgaria' },
  { id: '35', code: 'BFA', codeDescription: 'Burkina Faso' },
  { id: '36', code: 'BDI', codeDescription: 'Burundi' },
  { id: '37', code: 'CPV', codeDescription: 'Cabo Verde' },
  { id: '38', code: 'KHM', codeDescription: 'Cambodia' },
  { id: '39', code: 'CMR', codeDescription: 'Cameroon' },
  { id: '40', code: 'CAN', codeDescription: 'Canada' },
  { id: '41', code: 'CYM', codeDescription: 'Cayman Islands' },
  { id: '42', code: 'CAF', codeDescription: 'Central African Republic' },
  { id: '43', code: 'TDC', codeDescription: 'Chad' },
  { id: '44', code: 'CHL', codeDescription: 'Chile' },
  { id: '45', code: 'CHN', codeDescription: 'China' },
  { id: '46', code: 'CHR', codeDescription: 'Christmas Island' },
  { id: '47', code: 'CCK', codeDescription: 'Cocos (Keeling) Islands' },
  { id: '48', code: 'COL', codeDescription: 'Colombia' },
  { id: '49', code: 'COM', codeDescription: 'Comoros' },
  {
    id: '50',
    code: 'COD',
    codeDescription: 'Congo (the Democratic Republic of the)',
  },
  { id: '51', code: 'COG', codeDescription: 'Congo' },
  { id: '52', code: 'COK', codeDescription: 'Cook Islands' },
  { id: '53', code: 'CRI', codeDescription: 'Costa Rica' },
  { id: '54', code: 'HRV', codeDescription: 'Croatia' },
  { id: '55', code: 'CUB', codeDescription: 'Cuba' },
  { id: '56', code: 'CUW', codeDescription: 'Curatao' },
  { id: '57', code: 'CYP', codeDescription: 'Cyprus' },
  { id: '58', code: 'CZE', codeDescription: 'Czechia' },
  { id: '59', code: 'CIV', codeDescription: "Côte d'Ivoire" },
  { id: '60', code: 'DNK', codeDescription: 'Denmark' },
  { id: '61', code: 'DJI', codeDescription: 'Djibouti' },
  { id: '62', code: 'DMA', codeDescription: 'Dominica' },
  { id: '63', code: 'DOM', codeDescription: 'Dominican Republic' },
  { id: '64', code: 'ECU', codeDescription: 'Ecuador' },
  { id: '65', code: 'EGY', codeDescription: 'Egypt' },
  { id: '66', code: 'SLV', codeDescription: 'El Salvador' },
  { id: '67', code: 'GNQ', codeDescription: 'Equatorial Guinea' },
  { id: '68', code: 'ERI', codeDescription: 'Eritrea' },
  { id: '69', code: 'EST', codeDescription: 'Estonia' },
  { id: '70', code: 'SWZ', codeDescription: 'Eswatini' },
  { id: '71', code: 'ETH', codeDescription: 'Ethiopia' },
  { id: '72', code: 'FLK', codeDescription: 'Falkland Islands [Malvinas]' },
  { id: '73', code: 'FRO', codeDescription: 'Faroe Islands' },
  { id: '74', code: 'FJI', codeDescription: 'Fiji' },
  { id: '75', code: 'FIN', codeDescription: 'Finland' },
  { id: '76', code: 'FRA', codeDescription: 'France' },
  { id: '77', code: 'GUF', codeDescription: 'French Guiana' },
  { id: '78', code: 'PYF', codeDescription: 'French Polynesia' },
  { id: '79', code: 'ATF', codeDescription: 'French Southern Territories' },
  { id: '80', code: 'GAb', codeDescription: 'Gabon' },
  { id: '81', code: 'GMB', codeDescription: 'Gambia' },
  { id: '82', code: 'GEO', codeDescription: 'Georgia' },
  { id: '83', code: 'DEU', codeDescription: 'Germany' },
  { id: '84', code: 'GHA', codeDescription: 'Ghana' },
  { id: '85', code: 'GIB', codeDescription: 'Gibraltar' },
  { id: '86', code: 'GRC', codeDescription: 'Greece' },
  { id: '87', code: 'GRL', codeDescription: 'Greenland' },
  { id: '88', code: 'GRD', codeDescription: 'Grenada' },
  { id: '89', code: 'GLP', codeDescription: 'Guadeloupe' },
  { id: '90', code: 'GUM', codeDescription: 'Guam' },
  { id: '91', code: 'GTM', codeDescription: 'Guatemala' },
  { id: '92', code: 'GGY', codeDescription: 'Guernsey' },
  { id: '93', code: 'GIN', codeDescription: 'Guinea' },
  { id: '94', code: 'GNB', codeDescription: 'Guinea-Bissau' },
  { id: '95', code: 'GUY', codeDescription: 'Guyana' },
  { id: '96', code: 'HTI', codeDescription: 'Haiti' },
  {
    id: '97',
    code: 'HMD',
    codeDescription: 'Heard Island and McDonald Islands',
  },
  { id: '98', code: 'VAT', codeDescription: 'Holy See' },
  { id: '99', code: 'HND', codeDescription: 'Honduras' },
  { id: '100', code: 'HKG', codeDescription: 'Hong Kong' },
  { id: '101', code: 'HUN', codeDescription: 'Hungary' },
  { id: '102', code: 'ISL', codeDescription: 'Iceland' },
  { id: '103', code: 'IND', codeDescription: 'India' },
  { id: '104', code: 'IDN', codeDescription: 'Indonesia' },
  { id: '105', code: 'IRN', codeDescription: 'Iran (Islamic Republic of)' },
  { id: '106', code: 'IRQ', codeDescription: 'Iraq' },
  { id: '107', code: 'IRL', codeDescription: 'Ireland' },
  { id: '108', code: 'IMN', codeDescription: 'Isle of Man' },
  { id: '109', code: 'ISR', codeDescription: 'Israel' },
  { id: '110', code: 'ITA', codeDescription: 'Italy' },
  { id: '111', code: 'JAM', codeDescription: 'Jamaica' },
  { id: '112', code: 'JPN', codeDescription: 'Japan' },
  { id: '113', code: 'JEY', codeDescription: 'Jersey' },
  { id: '114', code: 'JOR', codeDescription: 'Jordan' },
  { id: '115', code: 'KAZ', codeDescription: 'Kazakhstan' },
  { id: '116', code: 'KEN', codeDescription: 'Kenya' },
  { id: '117', code: 'KIR', codeDescription: 'Kiribati' },
  {
    id: '118',
    code: 'PRK',
    codeDescription: "Korea (the Democratic People's Republic of)",
  },
  { id: '119', code: 'KOR', codeDescription: 'Korea (the Republic of)' },
  { id: '120', code: 'KWT', codeDescription: 'Kuwait' },
  { id: '121', code: 'KGZ', codeDescription: 'Kyrgyzstan' },
  {
    id: '122',
    code: 'LAO',
    codeDescription: "Lao People's Democratic Republic",
  },
  { id: '123', code: 'LVA', codeDescription: 'Latvia' },
  { id: '124', code: 'LBN', codeDescription: 'Lebanon' },
  { id: '125', code: 'LSO', codeDescription: 'Lesotho' },
  { id: '126', code: 'LBR', codeDescription: 'Liberia' },
  { id: '127', code: 'LBY', codeDescription: 'Libya' },
  { id: '128', code: 'LIE', codeDescription: 'Liechtenstein' },
  { id: '129', code: 'LTU', codeDescription: 'Lithuania' },
  { id: '130', code: 'LUX', codeDescription: 'Luxembourg' },
  { id: '131', code: 'MAC', codeDescription: 'Macao' },
  { id: '132', code: 'MDG', codeDescription: 'Madagascar' },
  { id: '133', code: 'MWI', codeDescription: 'Malawi' },
  { id: '134', code: 'MYS', codeDescription: 'Malaysia' },
  { id: '135', code: 'MDV', codeDescription: 'Maldives' },
  { id: '136', code: 'MLI', codeDescription: 'Mali' },
  { id: '137', code: 'MLT', codeDescription: 'Malta' },
  { id: '138', code: 'MHL', codeDescription: 'Marshall Islands' },
  { id: '139', code: 'MTQ', codeDescription: 'Martinique' },
  { id: '140', code: 'MRT', codeDescription: 'Mauritania' },
  { id: '141', code: 'MUS', codeDescription: 'Mauritius' },
  { id: '142', code: 'MYT', codeDescription: 'Mayotte' },
  { id: '143', code: 'MEX', codeDescription: 'Mexico' },
  {
    id: '144',
    code: 'FSM',
    codeDescription: 'Micronesia (Federated States of)',
  },
  { id: '145', code: 'MDA', codeDescription: 'Moldova (the Republic of)' },
  { id: '146', code: 'MCO', codeDescription: 'Monaco' },
  { id: '147', code: 'MNG', codeDescription: 'Mongolia' },
  { id: '148', code: 'MNE', codeDescription: 'Montenegro' },
  { id: '149', code: 'MSR', codeDescription: 'Montserrat' },
  { id: '150', code: 'MAR', codeDescription: 'Morocco' },
  { id: '151', code: 'MOZ', codeDescription: 'Mozambique' },
  { id: '152', code: 'MMR', codeDescription: 'Myanmar' },
  { id: '153', code: 'NAM', codeDescription: 'Namibia' },
  { id: '154', code: 'NRU', codeDescription: 'Nauru' },
  { id: '155', code: 'NPL', codeDescription: 'Nepal' },
  { id: '156', code: 'NLD', codeDescription: 'Netherlands' },
  { id: '157', code: 'NCL', codeDescription: 'New Caledonia' },
  { id: '158', code: 'NZL', codeDescription: 'New Zealand' },
  { id: '159', code: 'NIC', codeDescription: 'Nicaragua' },
  { id: '160', code: 'NER', codeDescription: 'Niger' },
  { id: '161', code: 'NGA', codeDescription: 'Nigeria' },
  { id: '162', code: 'NIU', codeDescription: 'Niue' },
  { id: '163', code: 'NFK', codeDescription: 'Norfolk Island' },
  { id: '164', code: 'MNP', codeDescription: 'Northern Mariana Islands (MP)' },
  { id: '165', code: 'NOR', codeDescription: 'Norway' },
  { id: '166', code: 'OMN', codeDescription: 'Oman' },
  { id: '167', code: 'PAK', codeDescription: 'Pakistan' },
  { id: '168', code: 'PLW', codeDescription: 'Palau' },
  { id: '169', code: 'PSE', codeDescription: 'Palestine, State of' },
  { id: '170', code: 'PAN', codeDescription: 'Panama' },
  { id: '171', code: 'PNG', codeDescription: 'Papua New Guinea' },
  { id: '172', code: 'PRY', codeDescription: 'Paraguay' },
  { id: '173', code: 'PER', codeDescription: 'Peru' },
  { id: '174', code: 'PHL', codeDescription: 'Philippines' },
  { id: '175', code: 'PCN', codeDescription: 'Pitcairn' },
  { id: '176', code: 'POL', codeDescription: 'Poland' },
  { id: '177', code: 'PRT', codeDescription: 'Portugal' },
  { id: '178', code: 'PRI', codeDescription: 'Puerto Rico' },
  { id: '179', code: 'QAT', codeDescription: 'Qatar' },
  { id: '180', code: 'MKD', codeDescription: 'Republic of North Macedonia' },
  { id: '181', code: 'ROU', codeDescription: 'Romania' },
  { id: '182', code: 'RUS', codeDescription: 'Russian Federation' },
  { id: '183', code: 'RWA', codeDescription: 'Rwanda' },
  { id: '184', code: 'REU', codeDescription: 'Réunion' },
  { id: '185', code: 'BLM', codeDescription: 'Saint Barthélemy' },
  {
    id: '186',
    code: 'SHN',
    codeDescription: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { id: '187', code: 'KNA', codeDescription: 'Saint Kitts and Nevis' },
  { id: '188', code: 'LCA', codeDescription: 'Saint Lucia' },
  { id: '189', code: 'MAF', codeDescription: 'Saint Martin (French part)' },
  { id: '190', code: 'SPM', codeDescription: 'Saint Pierre and Miquelon' },
  {
    id: '191',
    code: 'VCT',
    codeDescription: 'Saint Vincent and the Grenadines',
  },
  { id: '192', code: 'WSM', codeDescription: 'Samoa' },
  { id: '193', code: 'SMR', codeDescription: 'San Marino' },
  { id: '194', code: 'STP', codeDescription: 'Sao Tome and Principe' },
  { id: '195', code: 'SAU', codeDescription: 'Saudi Arabia' },
  { id: '196', code: 'SEN', codeDescription: 'Senegal' },
  { id: '197', code: 'SRB', codeDescription: 'Serbia' },
  { id: '198', code: 'SYC', codeDescription: 'Seychelles' },
  { id: '199', code: 'SLE', codeDescription: 'Sierra Leone' },
  { id: '200', code: 'SGP', codeDescription: 'Singapore' },
  { id: '201', code: 'SXM', codeDescription: 'Sint Maarten (Dutch part)' },
  { id: '202', code: 'SVK', codeDescription: 'Slovakia' },
  { id: '203', code: 'SVN', codeDescription: 'Slovenia' },
  { id: '204', code: 'SLB', codeDescription: 'Solomon Islands' },
  { id: '205', code: 'SOM', codeDescription: 'Somalia' },
  { id: '206', code: 'ZAF', codeDescription: 'South Africa' },
  {
    id: '207',
    code: 'SGS',
    codeDescription: 'South Georgia and the South Sandwich Islands',
  },
  { id: '208', code: 'SSD', codeDescription: 'South Sudan' },
  { id: '209', code: 'ESP', codeDescription: 'Spain' },
  { id: '210', code: 'LKA', codeDescription: 'Sri Lanka' },
  { id: '211', code: 'SDN', codeDescription: 'Sudan' },
  { id: '212', code: 'SUR', codeDescription: 'Suriname' },
  { id: '213', code: 'SJM', codeDescription: 'Svalbard and Jan Mayen' },
  { id: '214', code: 'SWE', codeDescription: 'Sweden' },
  { id: '215', code: 'CHE', codeDescription: 'Switzerland' },
  { id: '216', code: 'SYR', codeDescription: 'Syrian Arab Republic' },
  { id: '217', code: 'TWN', codeDescription: 'Taiwan (Province of China)' },
  { id: '218', code: 'TJK', codeDescription: 'Tajikistan' },
  { id: '219', code: 'TZA', codeDescription: 'Tanzania, United Republic of' },
  { id: '220', code: 'THA', codeDescription: 'Thailand' },
  { id: '221', code: 'TLS', codeDescription: 'Timor-Leste' },
  { id: '222', code: 'TGO', codeDescription: 'Togo' },
  { id: '223', code: 'TKL', codeDescription: 'Tokelau' },
  { id: '224', code: 'TON', codeDescription: 'Tonga' },
  { id: '225', code: 'TTO', codeDescription: 'Trinidad and Tobago' },
  { id: '226', code: 'TUN', codeDescription: 'Tunisia' },
  { id: '227', code: 'TUR', codeDescription: 'Turkey' },
  { id: '228', code: 'TKM', codeDescription: 'Turkmenistan' },
  { id: '229', code: 'TCA', codeDescription: 'Turks and Caicos Islands' },
  { id: '230', code: 'TUV', codeDescription: 'Tuvalu' },
  { id: '231', code: 'UGA', codeDescription: 'Uganda' },
  { id: '232', code: 'UKR', codeDescription: 'Ukraine' },
  { id: '233', code: 'ARE', codeDescription: 'United Arab Emirates' },
  {
    id: '234',
    code: 'GBR',
    codeDescription: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    id: '235',
    code: 'UMI',
    codeDescription: 'United States Minor Outlying Islands',
  },
  { id: '236', code: 'USA', codeDescription: 'United States of America' },
  { id: '237', code: 'URY', codeDescription: 'Uruguay' },
  { id: '238', code: 'UZB', codeDescription: 'Uzbekistan' },
  { id: '239', code: 'VUT', codeDescription: 'Vanuatu' },
  {
    id: '240',
    code: 'VEN',
    codeDescription: 'Venezuela (Bolivarian Republic of)',
  },
  { id: '241', code: 'VNM', codeDescription: 'Viet Nam' },
  { id: '242', code: 'VGB', codeDescription: 'Virgin Islands (British)' },
  { id: '243', code: 'VIR', codeDescription: 'Virgin Islands (U.S.)' },
  { id: '244', code: 'WLF', codeDescription: 'Wallis and Futuna' },
  { id: '245', code: 'ESH', codeDescription: 'Western Sahara' },
  { id: '246', code: 'YEM', codeDescription: 'Yemen' },
  { id: '247', code: 'ZMB', codeDescription: 'Zambia' },
  { id: '248', code: 'ZWE', codeDescription: 'Zimbabwe' },
  { id: '249', code: 'ALA', codeDescription: 'Åland Islands' },
];

export const states = [
  { id: '1', code: 'AK', codeDescription: 'Alaska' },
  { id: '2', code: 'AL', codeDescription: 'Alabama' },
  { id: '3', code: 'AR', codeDescription: 'Arkansas' },
  { id: '4', code: 'AZ', codeDescription: 'Arizona' },
  { id: '5', code: 'CA', codeDescription: 'California' },
  { id: '6', code: 'CO', codeDescription: 'Colorado' },
  { id: '7', code: 'CT', codeDescription: 'Connecticut' },
  { id: '8', code: 'DC', codeDescription: 'District of Columbia' },
  { id: '9', code: 'DE', codeDescription: 'Delaware' },
  { id: '10', code: 'FL', codeDescription: 'Florida' },
  { id: '11', code: 'GA', codeDescription: 'Georgia' },
  { id: '12', code: 'HI', codeDescription: 'Hawaii' },
  { id: '13', code: 'IA', codeDescription: 'Iowa' },
  { id: '14', code: 'ID', codeDescription: 'Idaho' },
  { id: '15', code: 'IL', codeDescription: 'Illinois' },
  { id: '16', code: 'IN', codeDescription: 'Indiana' },
  { id: '17', code: 'KS', codeDescription: 'Kansas' },
  { id: '18', code: 'KY', codeDescription: 'Kentucky' },
  { id: '19', code: 'LA', codeDescription: 'Louisiana' },
  { id: '20', code: 'MA', codeDescription: 'Massachusetts' },
  { id: '21', code: 'MD', codeDescription: 'Maryland' },
  { id: '22', code: 'ME', codeDescription: 'Maine' },
  { id: '23', code: 'MI', codeDescription: 'Michigan' },
  { id: '24', code: 'MN', codeDescription: 'Minnesota' },
  { id: '25', code: 'MO', codeDescription: 'Missouri' },
  { id: '26', code: 'MS', codeDescription: 'Mississippi' },
  { id: '27', code: 'MT', codeDescription: 'Montana' },
  { id: '28', code: 'NC', codeDescription: 'North Carolina' },
  { id: '29', code: 'ND', codeDescription: 'North Dakota' },
  { id: '30', code: 'NE', codeDescription: 'Nebraska' },
  { id: '31', code: 'NH', codeDescription: 'New Hampshire' },
  { id: '32', code: 'NJ', codeDescription: 'New Jersey' },
  { id: '33', code: 'NM', codeDescription: 'New Mexico' },
  { id: '34', code: 'NV', codeDescription: 'Nevada' },
  { id: '35', code: 'NY', codeDescription: 'New York' },
  { id: '36', code: 'OH', codeDescription: 'Ohio' },
  { id: '37', code: 'OK', codeDescription: 'Oklahoma' },
  { id: '38', code: 'OR', codeDescription: 'Oregon' },
  { id: '39', code: 'PA', codeDescription: 'Pennsylvania' },
  { id: '40', code: 'RI', codeDescription: 'Rhode Island' },
  { id: '41', code: 'SC', codeDescription: 'South Carolina' },
  { id: '42', code: 'SD', codeDescription: 'South Dakota' },
  { id: '43', code: 'TN', codeDescription: 'Tennessee' },
  { id: '44', code: 'TX', codeDescription: 'Texas' },
  { id: '45', code: 'UT', codeDescription: 'Utah' },
  { id: '46', code: 'VA', codeDescription: 'Virginia' },
  { id: '47', code: 'VT', codeDescription: 'Vermont' },
  { id: '48', code: 'WA', codeDescription: 'Washington' },
  { id: '49', code: 'WI', codeDescription: 'Wisconsin' },
  { id: '50', code: 'WV', codeDescription: 'West Virginia' },
  { id: '51', code: 'WY', codeDescription: 'Wyoming' },
];

export const entryTypes = [
  { id: '1', code: 'TRD', codeDescription: 'TRD' },
  { id: '2', code: 'ACATC', codeDescription: 'ACATC' },
  { id: '3', code: 'ACATS', codeDescription: 'ACATS' },
  { id: '4', code: 'CSD', codeDescription: 'CSD' },
  { id: '5', code: 'DIV', codeDescription: 'DIV' },
  { id: '6', code: 'JNLC', codeDescription: 'JNLC' },
  { id: '7', code: 'JNLS', codeDescription: 'JNLS' },
  { id: '8', code: 'CORR', codeDescription: 'CORR' },
  { id: '9', code: 'INT', codeDescription: 'INT' },
  { id: '10', code: 'PTC', codeDescription: 'PTC' },
  { id: '11', code: 'PTR', codeDescription: 'PTR' },
  { id: '12', code: 'REORG', codeDescription: 'REORG' },
  { id: '13', code: 'SPIN', codeDescription: 'SPIN' },
  { id: '14', code: 'SPLIT', codeDescription: 'SPLIT' },
  { id: '15', code: 'DIVNRA', codeDescription: 'DIVNRA' },
  { id: '16', code: 'MA', codeDescription: 'MA' },
  { id: '17', code: 'CSD', codeDescription: 'CSD' },
  { id: '18', code: 'CSW', codeDescription: 'CSW' },
  { id: '19', code: 'FEE', codeDescription: 'FEE' },
  { id: '20', code: 'ALLOC', codeDescription: 'ALLOC' },
  { id: '21', code: 'CIL', codeDescription: 'CIL' },
  { id: '22', code: 'DIVWH', codeDescription: 'DIVWH' },
  { id: '23', code: 'INVPNL', codeDescription: 'INVPNL' },
  { id: '24', code: 'DIVPWH', codeDescription: 'DIVPWH' },
  { id: '25', code: 'FXTRD', codeDescription: 'FXTRD' },
  { id: '26', code: 'MEM', codeDescription: 'MEM' },
  { id: '28', code: 'MMSWP', codeDescription: 'MMSWP' },
  { id: '29', code: 'CFEE', codeDescription: 'CFEE' },
  { id: '30', code: 'WH', codeDescription: 'WH' },
  { id: '31', code: 'OCT', codeDescription: 'OCT' },
  { id: '32', code: 'DRS', codeDescription: 'DRS' },
  { id: '33', code: 'DWAC', codeDescription: 'DWAC' },
  { id: '34', code: 'DTC', codeDescription: 'DTC' },
  { id: '35', code: 'CNVS', codeDescription: 'CNVS' },
  { id: '36', code: 'CNVC', codeDescription: 'CNVC' },
  { id: '37', code: 'NC', codeDescription: 'NC' },
  { id: '38', code: 'DTCS', codeDescription: 'DTCS' },
  { id: '39', code: 'DTCC', codeDescription: 'DTCC' },
  { id: '40', code: 'NC', codeDescription: 'NC' },
  { id: '41', code: 'FXPNL', codeDescription: 'FXPNL' },
  { id: '42', code: 'VOF', codeDescription: 'VOF' },
  { id: '43', code: 'SWP', codeDescription: 'SWP' },
  { id: '44', code: 'EXTRD', codeDescription: 'EXTRD' },
  { id: '45', code: 'OPTRD', codeDescription: 'OPTRD' },
  { id: '46', code: 'OPEXC', codeDescription: 'OPEXC' },
  { id: '47', code: 'OPASN', codeDescription: 'OPASN' },
  { id: '48', code: 'OPEXP', codeDescription: 'OPEXP' },
];

export const entrySubTypes = [
  { id: '1', code: 'REG', codeDescription: 'REG' },
  { id: '2', code: 'TAF', codeDescription: 'TAF' },
  { id: '3', code: 'JIT', codeDescription: 'JIT' },
  { id: '4', code: 'FWH', codeDescription: 'FWH' },
  { id: '5', code: 'SWH', codeDescription: 'SWH' },
  { id: '6', code: 'SNC', codeDescription: 'SNC' },
  { id: '7', code: 'CNC', codeDescription: 'CNC' },
  { id: '8', code: 'SCNC', codeDescription: 'SCNC' },
  { id: '9', code: 'CDIV', codeDescription: 'CDIV' },
  { id: '10', code: 'SDIV', codeDescription: 'SDIV' },
  { id: '11', code: 'FSPLIT', codeDescription: 'FSPLIT' },
  { id: '12', code: 'RSPLIT', codeDescription: 'RSPLIT' },
  { id: '13', code: 'CMA', codeDescription: 'CMA' },
  { id: '14', code: 'SMA', codeDescription: 'SMA' },
  { id: '15', code: 'SCMA', codeDescription: 'SCMA' },
  { id: '16', code: 'MGN', codeDescription: 'MGN' },
  { id: '17', code: 'CDT', codeDescription: 'CDT' },
  { id: '18', code: 'SLWH', codeDescription: 'SLWH' },
  { id: '19', code: 'VTND', codeDescription: 'VTND' },
  { id: '20', code: 'VWRT', codeDescription: 'VWRT' },
  { id: '21', code: 'VRGT', codeDescription: 'VRGT' },
  { id: '22', code: 'VEXH', codeDescription: 'VEXH' },
  { id: '23', code: 'DP', codeDescription: 'DP' },
  { id: '24', code: 'WD', codeDescription: 'WD' },
  { id: '25', code: 'SWP', codeDescription: 'SWP' },
  { id: '26', code: 'QII', codeDescription: 'QII' },
];

export const vendors = [
  { id: '1', code: 'EGNYTE Inc', codeDescription: 'EGNYTE Inc' },
  { id: '2', code: 'Dunkin Donuts', codeDescription: 'Dunkin Donuts' },
  { id: '3', code: 'Michele Singh', codeDescription: 'Michele Singh' },
  {
    id: '4',
    code: 'Electronic Transaction Clearing',
    codeDescription: 'Electronic Transaction Clearing',
  },
  { id: '5', code: 'Jimmy Johns', codeDescription: 'Jimmy Johns' },
  { id: '6', code: 'UBER', codeDescription: 'UBER' },
  { id: '7', code: 'United Airlines', codeDescription: 'United Airlines' },
  { id: '8', code: 'BWI Creative Food', codeDescription: 'BWI Creative Food' },
  {
    id: '9',
    code: 'Financial Training Services Inc.',
    codeDescription: 'Financial Training Services Inc.',
  },
  { id: '10', code: 'UPS', codeDescription: 'UPS' },
  { id: '11', code: 'HELLOSIGN', codeDescription: 'HELLOSIGN' },
  { id: '12', code: 'FINRA CRD', codeDescription: 'FINRA CRD' },
  {
    id: '13',
    code: 'Knopman Marks Financial',
    codeDescription: 'Knopman Marks Financial',
  },
  {
    id: '14',
    code: 'KAPLAN-JYMONK-DEARBORN',
    codeDescription: 'KAPLAN-JYMONK-DEARBORN',
  },
  {
    id: '15',
    code: 'ETC Clearing Corp.',
    codeDescription: 'ETC Clearing Corp.',
  },
  { id: '16', code: 'Hilton Hotels', codeDescription: 'Hilton Hotels' },
  { id: '17', code: 'NSCP', codeDescription: 'NSCP' },
  { id: '17', code: 'Biometrics', codeDescription: 'Biometrics' },
  { id: '17', code: 'USPS', codeDescription: 'USPS' },
  {
    id: '17',
    code: 'Greyline Solutions LLC',
    codeDescription: 'Greyline Solutions LLC',
  },
  {
    id: '17',
    code: 'First Republic Bank',
    codeDescription: 'First Republic Bank',
  },
  { id: '17', code: 'Zendesk, Inc.', codeDescription: 'Zendesk, Inc.' },
  {
    id: '17',
    code: 'CFB Board of Standards, Inc.',
    codeDescription: 'CFB Board of Standards, Inc.',
  },
  {
    id: '17',
    code: 'Alvarez & Associates, Inc.',
    codeDescription: 'Alvarez & Associates, Inc.',
  },
  { id: '17', code: 'SVB Bank', codeDescription: 'SVB Bank' },
  {
    id: '17',
    code: 'Southwest Airlines',
    codeDescription: 'Southwest Airlines',
  },
  {
    id: '17',
    code: 'Wolverine Securities',
    codeDescription: 'Wolverine Securities',
  },
  { id: '17', code: 'Alpaca DB', codeDescription: 'Alpaca DB' },
  {
    id: '17',
    code: 'Johnson Rivers Lyons, LLC',
    codeDescription: 'Johnson Rivers Lyons, LLC',
  },
  { id: '17', code: 'Global Relay', codeDescription: 'Global Relay' },
  {
    id: '17',
    code: 'The NASDAQ Stock Market, LLC.',
    codeDescription: 'The NASDAQ Stock Market, LLC.',
  },
  {
    id: '17',
    code: 'Trulioo Information Services',
    codeDescription: 'Trulioo Information Services',
  },
  {
    id: '17',
    code: 'Lowenstein Sandler LLP',
    codeDescription: 'Lowenstein Sandler LLP',
  },
  { id: '17', code: 'CSC', codeDescription: 'CSC' },
  { id: '17', code: 'NRS', codeDescription: 'NRS' },
  {
    id: '17',
    code: 'APEX Clearing Corporation',
    codeDescription: 'APEX Clearing Corporation',
  },
  { id: '17', code: 'PLAID Inc.', codeDescription: 'PLAID Inc.' },
  {
    id: '17',
    code: 'ETC Misc. Fee Account 1212062017',
    codeDescription: 'ETC Misc. Fee Account 1212062017',
  },
];

export const callStatusReasons = [
  {
    id: '1',
    code: 'ACH/Wire initiated',
    codeDescription: 'ACH/Wire initiated',
  },
  { id: '2', code: 'ACH/Wire recieved', codeDescription: 'ACH/Wire recieved' },
  { id: '3', code: 'Call error', codeDescription: 'Call error' },
  { id: '4', code: 'Liquidated', codeDescription: 'Liquidated' },
  { id: '5', code: 'Auto-satisfy', codeDescription: 'Auto-satisfy' },
];
